import './ChargeInfo.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { findIndex } from 'lodash';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';

const ChargeInfo = ({ charge, client, installments, faturaUUID }) => {

  const [paidAt, setPaidAt] = useState(false);
  const [datePaidAt, setDatePaidAt] = useState("");
  const [hourPaidAt, setHourPaidAt] = useState("");

  useEffect(() => {
    if (charge.paid_at === null) {
      setPaidAt(false);
    } else {
      var splitPaidAt = charge.paid_at.split("T");

      var date = Object.values(splitPaidAt)[0];
      var hour = Object.values(splitPaidAt)[1].replace("Z", "");

      setDatePaidAt(date);
      setHourPaidAt(hour);

      setPaidAt(true);
    }
  }, [charge])

  const formatDate = (date) => {
    const day = date.split('-')[2];
    const month = date.split('-')[1];
    const year = date.split('-')[0];
    return `${day}/${month}/${year}`;
  }
  const rangeToPay = () => {
    const currentDate = new Date();
    const millisecondsPerDay = 1000 * 3600 * 24;
    const dias = Math.round((new Date(charge.due_at) - currentDate) / millisecondsPerDay);
    return (dias < 0) ? `(atrasado a ${Math.abs(dias)} dias)` : `(vence daqui a ${dias} dias)`;
  }

  const description = () => {
    const currentBill = findIndex(installments, { current: true }) + 1;
    const totalBills = installments.length;
    return installments.length > 1 ? `Parcela ${currentBill} de ${totalBills}` : `Parcela única.`
  }

  let navigate = useNavigate();

  const historyParcela = (e) => {
    if (faturaUUID !== e) {
      navigate(`/i/${e}`)
    }
  }

  return (
    <div className="charge-info">
      <section className="box-section">
        <h1>Dados da fatura</h1>
        {/* <h1>Dados da fatura <span>00642844</span></h1> */}
        <div className="box-section-content">
          <div>
            <span>Valor</span>
            <p className="valor-boleto">{charge.amount.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}</p>
          </div>
          {
            paidAt ?
              <div>
                <span>Data de pagamento</span>
                <p className="data-vencimento">{formatDate(datePaidAt)}</p>
                <span>Hora do pagamento: {hourPaidAt}</span>
              </div>
              :
              <div>
                <span>Data de vencimento</span>
                <p className="data-vencimento">{formatDate(charge.due_at)}</p>
                <span>{rangeToPay()}</span>
              </div>
          }
          {
            installments && <div>
              <div>
                <span>Descrição</span>
                <p>{description()}</p>
              </div>
              <div>
                <span>Parcela</span>
                <p>{description()}</p>
              </div>
            </div>
          }

        </div>
      </section>

      <section className="box-section">
        <div className="box-section-content">
          <h1>Dados do comprador</h1>
          <span>Nome:</span>
          <p>{client.name}</p>
          <span>E-mail:</span>
          <p>{client.email}</p>
        </div>
      </section>

      {installments && <section className="box-section installments-section">
        <div className="box-section-content">
          <h1>Resumo do parcelamento</h1>
          <ul>
            {installments.map((installment, i) => {
              return (
                <li className={installment.current ? 'current' : ''} key={`installment_${i}`} onClick={() => historyParcela(installment.id)}>
                  <div><span>{i + 1})</span>  <FontAwesomeIcon icon={faCalendarDay} className="icon-calender" />{formatDate(installment.due_at)}</div>
                  <div>{installment.amount.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}</div>
                </li>
              )
            })}
          </ul>
        </div>
      </section>}
    </div>
  )
}

export default ChargeInfo;