import './PaymentType.scss';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaCheckCircle, FaPrint, FaCircleNotch, FaRegEye, FaRegCopy, FaExclamationTriangle, FaRegCheckCircle } from "react-icons/fa";
import { faBarcode, faCreditCard, faPrint, faCopy } from '@fortawesome/free-solid-svg-icons';

import copy from 'copy-to-clipboard';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { trim } from 'lodash';
import InputMask from 'react-input-mask';

import mastercardFlag from '../../imgs/creditcard/mastercard-2f5f4b4c6ce497f53017449ac9cb2064.png';
import mdiscoverFlag from '../../imgs/creditcard/discover-36148d1dbb7dd2cf1384153daaafb026.png';
import eloFlag from '../../imgs/creditcard/elo-76100118777e009f8a0a9ec8e560f498.png';
import hipercardFlag from '../../imgs/creditcard/hipercard-cc3bf1bb6bb7003e85a8033f2c4db5c6.png';
import visaFlag from '../../imgs/creditcard/visa-6ffaa843b9c7e9776e8612f6f05b83cd.png';
import amexFlag from '../../imgs/creditcard/amex-c2edf0d6850e69a5a05e366971f587db.png';

const schema = yup.object().shape({
  num_cartao: yup.string()
    .transform(value => trim(value))
    .min(19)
    .required('É preciso colocar o número do cartão'),
  titular_cartao: yup.string()
    .min(3, 'Pelo menos 3 caracteres.')
    .required(),
  mes_cartao: yup.number()
    .typeError('Mês de validade.')
    .lessThan(13, 'Mês inválido')
    .moreThan(0, 'Mês inválido')
    .required(),
  ano_cartao: yup.number()
    .typeError('Ano de validade.')
    .moreThan(20, 'Ano inválido')
    .required(),
  cod_cartao: yup.string()
    .transform(value => trim(value))
    .min(3, 'Pelo menos 3 dígitos')
    .required(),
}).required();

const PaymentType = ({ fatura, faturaUUID }) => {

  //States para modal
  const [modalCopy, setModalCopy] = useState(false);
  const [loadModal, setLoadModal] = useState(false);
  const [alertCard, setAlertCard] = useState(false);
  const [alertCardSucess, setAlertCardSucess] = useState(false);
  const [brandSelection, setBrandSelection] = useState(false);
  const [installmentsSelection, setInstallmentsSelection] = useState(false);
  const [messageModal, setMessageModal] = useState("");

  //States para coleta de informações da API
  const [infoPayment, setInfoPayment] = useState([]);
  const [typePayment, setTypePayment] = useState(0);
  const [statusPayment, setStatusPayment] = useState(0);
  const [urlBoleto, setUrlBoleto] = useState("");
  const [boleto, setBoleto] = useState(true);
  const [installments, setInstallments] = useState(1)

  //States para captura de dados
  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardValidate, setCardValidade] = useState("");
  const [cardCvv, setCardCvv] = useState("");
  const [brand, setBrand] = useState("");
  const [brandStyle, setBrandStyle] = useState("none");

  const { formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  var generateBoletoUrl = process.env.REACT_APP_CHARGES_INVOICE_URL + faturaUUID + process.env.REACT_APP_BOLETO_URL;
  var submitURL = process.env.REACT_APP_CHARGES_INVOICE_URL + faturaUUID + process.env.REACT_APP_CARD_URL;

  useEffect(() => {
    const boleto_EXISTS = fatura.charge.boleto;
    const type_PAYMENT = fatura.charge.type;

    setTypePayment(type_PAYMENT);
    setInfoPayment(fatura);

    if (boleto_EXISTS !== undefined && boleto_EXISTS !== null) {
      const boleto_URL = fatura.charge.boleto.url;
      setUrlBoleto(boleto_URL);
      setBoleto(false)
    } else {
      setBoleto(true)
      setUrlBoleto("")
    }
    if (fatura.installments === undefined || fatura.installments === null || fatura.installments === "") {
      setInstallments(1);
    } else {
      setInstallments(fatura.installments.length);
    }
    if (fatura.charge.status === 5) {
      setStatusPayment(true)
    }
  }, [fatura]);

  useEffect(() => {
    return () => { };
  }, [errors]);

  //Arrow Function para descobrir bandeira do cartão
  const cardFlag = (e) => {
    let cn_underline = e.replace(/_/g, "");
    let cn = cn_underline.replace(/ /g, "");
    var cardnumber = cn.replace(/[^0-9]+/g, '');

    setCardNumber(cn)

    var regexVisa = /^4[0-9]{12}(?:[0-9]{3})?/;
    var regexMaster = /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/;
    var regexAmex = /^3[47][0-9]{13}/;
    var regexElo = /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/;
    var regexAura = /^(5078\d{2})(\d{2})(\d{11})$/;
    var regexJCB = /^(?:2131|1800|35\d{3})\d{11}/;
    var regexDiners = /^3(?:0[0-5]|[68][0-9])[0-9]{11}/;
    var regexDiscover = /^6(?:011|5[0-9]{2})[0-9]{12}/;
    var regexHipercard = /^(606282\d{10}(\d{3})?)|(3841\d{15})/;

    if (regexVisa.test(cardnumber)) {
      setBrand("Visa")
      setBrandStyle("Visa")
    }
    if (regexMaster.test(cardnumber)) {
      setBrand("Master")
      setBrandStyle("Master")
    }
    if (regexAmex.test(cardnumber)) {
      setBrand("Amex")
      setBrandStyle("Amex")
    }
    if (regexElo.test(cardnumber)) {
      setBrand("Elo")
      setBrandStyle("Elo")
    }
    if (regexAura.test(cardnumber)) {
      setBrand("Aura")
      setBrandStyle("Aura")
    }
    if (regexJCB.test(cardnumber)) {
      setBrand("JBC")
      setBrandStyle("JBC")
    }
    if (regexDiners.test(cardnumber)) {
      setBrand("Diners")
      setBrandStyle("Diners")
    }
    if (regexDiscover.test(cardnumber)) {
      setBrand("Discover")
      setBrandStyle("Discover")
    }
    if (regexHipercard.test(cardnumber)) {
      setBrand("Hipercard")
      setBrandStyle("Hipercard")
    }
  }

  const installmentSelection = () => {
    setInstallmentsSelection(true)
  }

  const onSubmit = () => {
    if (brand === "") {
      setBrandSelection(true)
    } else {
      //formatando os dados 
      let cv = cardValidate.replace(/_/g, "");
      let ccvv = cardCvv.replace(/_/g, "")

      //Validando informações do cartão
      if (cardName.length > 4 && cardName.length < 45) {
        if (cardNumber.length === 16) {
          if (cv.length === 7) {
            var strData = "01/" + cv;
            var partesData = strData.split("/");
            var data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
            if (data > new Date()) {
              if (partesData[1] >= 1 && partesData[1] <= 12) {
                if (ccvv.length === 3 || ccvv.length === 4) {

                  //Submit API
                  const dataForm = {
                    "name": cardName,
                    "number": cardNumber,
                    "due_at": cv,
                    "cvv": ccvv,
                    "brand": brand,
                    "installments": installments
                  }

                  fetch(submitURL, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dataForm),
                  }).then((resp) => resp.json())
                    .then(item => {
                      if (item.status === 3) {

                        setAlertCardSucess(true)
                        setMessageModal("Pagamento realizado com sucesso!")
                        setTimeout(() => {
                          window.location.reload()
                        }, 2500);

                      } else if (item.status === 10) {
                        setAlertCardSucess(true)
                        setMessageModal("Transação autorizada, aguarde o processamento!")
                        setTimeout(() => {
                          window.location.reload()
                        }, 2500);
                      } else if (item.status === 11) {

                        setAlertCard(true)
                        setMessageModal("Não autorizado!")
                        setTimeout(() => {
                          setAlertCard(false)
                        }, 3000);

                      } else {

                        setAlertCard(true)
                        setMessageModal("Ocorreu um erro no pagamento!")
                        setTimeout(() => {
                          setAlertCard(false)
                        }, 2500);

                      }
                    }).catch((error) => {
                      console.log(error)
                    })
                } else {
                  setAlertCard(true)
                  setMessageModal("Código de segurança inválido")
                  setTimeout(() => {
                    setAlertCard(false)
                  }, 3000);
                }
              } else {
                setAlertCard(true)
                setMessageModal("Mês de validade inválido")
                setTimeout(() => {
                  setAlertCard(false)
                }, 3000);
              }
            }
            else {
              setAlertCard(true)
              setMessageModal("Cartão Vencido")
              setTimeout(() => {
                setAlertCard(false)
              }, 3000);
            }
          } else {
            setAlertCard(true)
            setMessageModal("Validade do cartão inválida")
            setTimeout(() => {
              setAlertCard(false)
            }, 3000);
          }
        } else {
          setAlertCard(true)
          setMessageModal("Número do cartão inválido")
          setTimeout(() => {
            setAlertCard(false)
          }, 3000);
        }
      } else {
        setAlertCard(true)
        setMessageModal("Nome do cartão inválido")
        setTimeout(() => {
          setAlertCard(false)
        }, 3000);
      }
    }
  }

  //começo gerar boleto
  const generateBoleto = () => {
    fetch(generateBoletoUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((resp) => resp.json())
      .then(item => {
        if (item.error) {
          setMessageModal("Erro ao gerar o boleto...")
          setAlertCard(true)
          setLoadModal(false)
          setTimeout(() => {
            setAlertCard(false)
          }, 3000);
        }
      })
      .catch(Erro => console.log(Erro));
  }


  const consultBoletoExist = () => {
    fetch(process.env.REACT_APP_SHOW_PAYMENT_URL + faturaUUID, {
      method: 'GET'
    })
      .then((resp) => resp.json())
      .then(item => {
        if (item.charge.boleto !== undefined && item.charge.boleto !== null) {
          setBoleto(false)
          setLoadModal(false)
          setUrlBoleto(item.charge.boleto.url)
        }
        setInfoPayment(item)
      })
      .catch(Erro => console.log(Erro));
  }
  //fim gerar boleto

  //Arrow function para gerar e atualizar componente do boleto
  const boletoFunctions = () => {
    setLoadModal(true)
    generateBoleto()
    setTimeout(() => {
      consultBoletoExist()
    }, 4500);

  }

  //Arrow function para copiar o código de barras
  const copyBarCode = () => {
    setModalCopy(true)
    copy(infoPayment.charge.boleto.digitable_line)
    setTimeout(() => {
      setModalCopy(false)
    }, 3000);
  }

  //Arrow function para imprimir boleto
  const ImprimirBoleto = () => {
    window.open(urlBoleto + "?print=True")
  }

  //Arrow function para visualizar boleto
  const OpenBoleto = () => {
    window.open(urlBoleto)
  }

  const continueBrand = () => {
    setBrandSelection(false);
    onSubmit();
  }

  const continueInstallment = () => {
    setInstallmentsSelection(false);
    onSubmit();
  }


  const getInstallmentAmount = (amount, installment) => {
    const value = parseFloat((amount / installment).toFixed(3).slice(0,-1))
    //console.log(value);
    //console.log(parseFloat(value).toLocaleString("pt-BR", { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' }));
    return value.toLocaleString("pt-BR", { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })
  }

  return (
    <div className={!statusPayment ? "content-payment-type" : "content-payment-type-none"}>
      <div className="payment-type">
        {
          typePayment === 1 ?
            <h2 id="title-selecione">Escolha a forma de pagamento</h2>
            :
            <span></span>
        }
      </div>
      <div className="btns-payment-types">
        <div className="payment-type-unique">
          <div className={typePayment === 3 ? "display-center card" : "card-off"}>
            <div className="padding-btn">
              <div className="type-container">
                <div className="content-type-payment">
                  <div className="title-payment">
                    <h2>Finalização do pagamento</h2>
                  </div>
                  <div className="flags-card">
                    {/* {installments && <p>Serão cobradas no seu cartão as {installments.length} parcelas restantes no valor de R${installments[0].amount} ao mês, totalizando R${installments[0].amount * installments.length}.</p>} */}
                    <span>Aceitamos:</span>
                    <div className="flags">
                      <img src={mastercardFlag} alt={mastercardFlag} title="Visa" width="40" height="30" />
                      <img src={mdiscoverFlag} alt={mdiscoverFlag} title="MasterCard" width="40" height="30" />
                      <img src={eloFlag} alt={eloFlag} title="Elo" width="40" height="30" />
                      <img src={hipercardFlag} alt={hipercardFlag} title="Hipercard" width="40" height="30" />
                      <img src={visaFlag} alt={visaFlag} title="Discover" width="40" height="30" />
                      <img src={amexFlag} alt={amexFlag} title="Amex" width="40" height="30" />
                    </div>
                  </div>
                  <div className="form-card">
                    {/* <form onSubmit={onSubmit}> */}
                    <div className="input-form-card">
                      <label htmlFor="card_number">Número do Cartão</label>
                      <InputMask mask="9999 9999 9999 9999" id='card_number' maskChar='' onChange={e => cardFlag(e.target.value)} placeholder="____ ____ ____ ____" type="text" autoComplete="off" className={errors.num_cartao && 'required'}  ></InputMask>
                    </div>
                    <div className="input-form-card">
                      <label htmlFor="card_name">Nome do Titular do Cartão</label>
                      <input type="text" id="card_name" placeholder="Nome impresso no cartão" onChange={e => setCardName(e.target.value)} autoComplete="off" className={errors.titular_cartao && 'required'} />
                    </div>
                    <div className="flex-form-card">
                      <div className="input-form-card-flex">
                        <label htmlFor="card_date">Validade</label>
                        <InputMask mask="99/9999" maskChar='_' id="card_date" placeholder="__/____" onChange={e => setCardValidade(e.target.value)} autoComplete="off" className={errors.mes_cartao && 'required'} ></InputMask>
                      </div>
                      <div className="input-form-card-flex">
                        <label htmlFor="card_cvv">Código de Segurança</label>
                        <InputMask mask="9999" id="card_cvv" maskChar='' placeholder="____" autoComplete="off" onChange={e => setCardCvv(e.target.value)} className={errors.cod_cartao && 'required'}></InputMask>
                      </div>
                    </div>
                    <div>
                    </div>
                    <input type="submit" className="btn" value="Continuar" onClick={installmentSelection} />
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={typePayment === 2 ? "display-center boleto" : "boleto-off"}>
            <div className="padding-btn">
              <div className="type-container">
                <div className="content-type-payment">
                  <div className="title-payment">
                    <h2>Finalização do pagamento</h2>
                  </div>
                  <div className={boleto ? "generate-boleto" : "generate-boleto-sucess"}>
                    <div className="padding-btn-payment">
                      <button className="btn-type" onClick={boletoFunctions}>Gerar Boleto</button>
                    </div>
                  </div>
                  <div className={boleto ? "payment-buttons-boleto-off" : "payment-buttons-boleto"}>
                    <div className="padding-btn-payment">
                      <button className="btn-type" onClick={OpenBoleto}><FontAwesomeIcon icon={faPrint} id='margin-right' /> Visualizar Boleto</button>
                    </div>
                    <div className="padding-btn-payment">
                      <button className="btn-type" onClick={ImprimirBoleto}><FontAwesomeIcon icon={faPrint} id='margin-right' /> Imprimir Boleto</button>
                    </div>
                    <div className="padding-btn-payment">
                      <button className="btn-type" onClick={copyBarCode}><FontAwesomeIcon icon={faCopy} id='margin-right' /> Cód. de Barras</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={typePayment === 1 ? "payment-buttons" : "payment-buttons-off"}>
          {/* Cartão */}
          <div className="padding-btn">
            <span className="span-type"><FontAwesomeIcon icon={faCreditCard} id="margin-right" /> Cartão</span>
            <div className="type-container">
              <div className="content-type-payment">
                <div className="title-payment">
                  <h2>Cartão para pagamento</h2>
                </div>
                <div className="flags-card">
                  {/* {installments && <p>Serão cobradas no seu cartão as {installments.length} parcelas restantes no valor de R${installments[0].amount} ao mês, totalizando R${installments[0].amount * installments.length}.</p>} */}
                  <span>Aceitamos:</span>
                  <div className="flags">
                    <img src={mastercardFlag} id={brandStyle === 'Master' ? 'active-brand' : 'off-brand'} alt={mastercardFlag} title="Visa" width="40" height="30" />
                    <img src={mdiscoverFlag} id={brandStyle === 'Discover' ? 'active-brand' : 'off-brand'} alt={mdiscoverFlag} title="MasterCard" width="40" height="30" />
                    <img src={eloFlag} id={brandStyle === 'Elo' ? 'active-brand' : 'off-brand'} alt={eloFlag} title="Elo" width="40" height="30" />
                    <img src={hipercardFlag} id={brandStyle === 'Hipercard' ? 'active-brand' : 'off-brand'} alt={hipercardFlag} title="Hipercard" width="40" height="30" />
                    <img src={visaFlag} id={brandStyle === 'Visa' ? 'active-brand' : 'off-brand'} alt={visaFlag} title="Discover" width="40" height="30" />
                    <img src={amexFlag} id={brandStyle === 'Amex' ? 'active-brand' : 'off-brand'} alt={amexFlag} title="Amex" width="40" height="30" />
                  </div>
                </div>
                <div className="form-card">
                  {/* <form onSubmit={onSubmit}> */}
                  {/* <form> */}
                  <div className="input-form-card">
                    <label htmlFor="card_number">Número do Cartão</label>
                    <InputMask mask="9999 9999 9999 9999" id='card_number' maskChar='' placeholder="____ ____ ____ ____" onChange={e => cardFlag(e.target.value)} type="text" autoComplete="off" className={errors.num_cartao && 'required'}  ></InputMask>
                  </div>
                  <div className="input-form-card">
                    <label htmlFor="card_name">Nome do Titular do Cartão</label>
                    <input type="text" id="card_name" placeholder="Nome impresso no cartão" autoComplete="off" onChange={e => setCardName(e.target.value)} className={errors.titular_cartao && 'required'} />
                  </div>
                  <div className="flex-form-card">
                    <div className="input-form-card-flex">
                      <label htmlFor="card_date">Validade</label>
                      <InputMask mask="99/9999" maskChar='_' id="card_date" placeholder="__/____" autoComplete="off" onChange={e => setCardValidade(e.target.value)} className={errors.mes_cartao && 'required'} ></InputMask>
                    </div>
                    <div className="input-form-card-flex">
                      <label htmlFor="card_cvv">Cvv</label>
                      <InputMask mask="9999" id="card_cvv" maskChar='' placeholder="____" autoComplete="off" onChange={e => setCardCvv(e.target.value)} className={errors.cod_cartao && 'required'}></InputMask>
                    </div>
                  </div>
                  <div>
                  </div>
                  <div className="padding-btn-top">
                    <input type="submit" className="btn" value="Continuar" onClick={installmentSelection} />
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
          {/* Boleto */}
          <div className="padding-btn">
            <span className="span-type"><FontAwesomeIcon icon={faBarcode} id="margin-right" /> Boleto</span>
            <div className="type-container">
              <div className="content-type-payment">
                <div className="title-payment">
                  <h2>Boleto bancário para pagamento</h2>
                </div>
                <div className={boleto ? "generate-boleto" : "generate-boleto-sucess"}>
                  <div className="padding-btn-payment">
                    <button className="btn-type" onClick={boletoFunctions}>Gerar Boleto</button>
                  </div>
                </div>
                <div className={boleto ? "payment-buttons-boleto-off" : "payment-buttons-boleto"}>
                  <div className="padding-btn-payment">
                    <button className="btn-type" onClick={OpenBoleto}><FaRegEye size="1rem" color='f3f3f3' id='margin-right' /> Visualizar Boleto</button>
                  </div>
                  <div className="padding-btn-payment">
                    <button className="btn-type" onClick={ImprimirBoleto}><FaPrint size="1rem" color='f3f3f3' id='margin-right' /> Imprimir Boleto</button>
                  </div>
                  <div className="padding-btn-payment">
                    <button className="btn-type" onClick={copyBarCode}><FaRegCopy size="1rem" color='f3f3f3' id='margin-right' /> Cód. de Barras</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Copy to clipboard */}
      <div className={modalCopy ? "container-modal-copy" : "modal-off"}>
        <div className="content-modal-copy">
          <FaCheckCircle color="00AB66" size="1.5rem" id="margin-right" />
          <span>Código de barras copiado.</span>
        </div>
      </div>
      {/* Modal copy to clipboard */}
      {/* Modal gerar boleto */}
      <div className={loadModal ? "container-modal-copy" : "modal-off"}>
        <div className="content-modal-copy">
          <FaCircleNotch color="00AB66" size="2.5rem" id="rotate" />
          <span>Gerando boleto, aguarde..</span>
        </div>
      </div>
      {/* Modal gerar boleto*/}
      {/* Modal erro credit card */}
      <div className={alertCard ? "container-modal-copy" : "modal-off"}>
        <div className="content-modal-copy">
          <FaExclamationTriangle color="FAD02C" size="2.5rem" id="rotate" />
          <span>{messageModal}</span>
        </div>
      </div>
      {/* Modal erro credit card*/}
      {/* Modal sucess credit card */}
      <div className={alertCardSucess ? "container-modal-copy" : "modal-off"}>
        <div className="content-modal-copy">
          <FaRegCheckCircle color="4BB543" size="2.5rem" id="rotate" />
          <span>{messageModal}</span>
        </div>
      </div>
      {/* Modal erro credit card*/}
      {/* Modal sucess credit card */}
      <div className={brandSelection ? "container-modal-copy" : "modal-off"}>
        <div className="content-modal-copy">
          <span id="padding">Bandeira não indentificada, selecione a bandeira:</span>
          <select name="brand-selection" id="brand-selection" onChange={e => setBrand(e.target.value)}>
            <option value="Visa">Visa</option>
            <option value="Master">Master</option>
            <option value="Amex">Amex</option>
            <option value="Elo">Elo</option>
            <option value="Aura">Aura</option>
            <option value="JBC">JBC</option>
            <option value="Diners">Diners</option>
            <option value="Discover">Discover</option>
            <option value="Hipercard">Hipercard</option>
            <option value="Hiper">Hiper</option>
          </select>
          <br />
          <button onClick={continueBrand}>Continuar</button>
        </div>
      </div>
      {/* Modal erro credit card*/}
      {/* Modal sucess credit card */}
      <div className={installmentsSelection ? "container-modal-copy" : "modal-off"}>
        <div className="content-modal-copy">
          <span id="padding">Selecione a quantidade de parcelas:</span>
          <select name="brand-selection" id="brand-selection" onChange={e => setInstallments(e.target.value)}>
            <option value="1">1x {getInstallmentAmount(fatura.charge.amount, 1)}</option>
            <option value="2">2x {getInstallmentAmount(fatura.charge.amount, 2)}</option>
            <option value="3">3x {getInstallmentAmount(fatura.charge.amount, 3)}</option>
            <option value="4">4x {getInstallmentAmount(fatura.charge.amount, 4)}</option>
            <option value="5">5x {getInstallmentAmount(fatura.charge.amount, 5)}</option>
            <option value="6">6x {getInstallmentAmount(fatura.charge.amount, 6)}</option>
            <option value="7">7x {getInstallmentAmount(fatura.charge.amount, 7)}</option>
            <option value="8">8x {getInstallmentAmount(fatura.charge.amount, 8)}</option>
            <option value="9">9x {getInstallmentAmount(fatura.charge.amount, 9)}</option>
            <option value="10">10x {getInstallmentAmount(fatura.charge.amount, 10)}</option>
          </select>
          <br />
          <button onClick={continueInstallment}>Continuar</button>
        </div>
      </div>
      {/* Modal erro credit card*/}
    </div >
  )
}

export default PaymentType;


