import './PaymentStatus.scss';

const PaymentStatus = ({ charge }) => {

  const arStatus = [
    { text: 'Pendente', color: 'orange' },
    { text: 'Processando', color: 'orange' },
    { text: 'Pago', color: 'green' },
    { text: 'Vencido', color: 'red' },
    { text: 'Cancelado', color: 'red' },
    { text: 'Fraude', color: 'red' },
    { text: 'Com pendências', color: 'orange' },
    { text: 'Chargeback', color: 'orange' },
    { text: 'Recebido em dinheiro', color: 'green' },
    { text: 'Autorizado', color: 'green' },
    { text: 'Não autorizado', color: 'red' }];
  const statusInfo = arStatus[charge.status - 1];

  return (
    <section className="box-section payment-status">
      <div className="box-section-content">
        <p className={statusInfo.color}>{statusInfo.text}</p>
        {/* <a href="/">Reportar um problema <FontAwesomeIcon icon={faCommentDots} /></a> */}
      </div>
    </section>
  );
}

export default PaymentStatus;