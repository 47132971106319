import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.scss';
import WrongPage from './components/WrongPage/WrongPage';
import Home from './components/Home/Home';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path='/:faturaUUID' element={<Home />}></Route>
      </Routes>
    </Router>
  );
}

export default App;