import './Header.scss';
import { useState, useEffect} from 'react';

const Header = ({ account }) => {

  const [logoE, setLogo] = useState("");
  const logo = account.personalization.logo ? account.personalization.logo : null;

  useEffect( () => {
    setLogo(account.personalization.logo);

  }, [account, logo])
  
  return (
    <header className="header">
      <div className={`logo-container ${logo ? 'show-logo' : ''}`}>
        <img src={logoE} alt="image-logo" />
      </div>
      <h1>{account.name}</h1>
      <span>{account.document}</span>
      <ul>
        <li>{account.email}</li>
        <li>{account.phone.number}</li>
        <li>{account.address.neighborhood} - {account.address.city.name}</li>
      </ul>
    </header>
  )
}

export default Header;
