// import { reduce } from 'lodash';

const arStatusDisplayPayment = [1, 4, 5, 7, 8, 10, 11];

const callApi = (faturaID) => {
  const apiURL = process.env.REACT_APP_SHOW_PAYMENT_URL + faturaID;
  const apiHeader = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    mode: 'cors',
    cache: 'default'
  };

  const beeInvoiceApi = new Request(apiURL, apiHeader);
  return fetch(beeInvoiceApi).then(response => response.json());
}

const displayPayment = (status) => {
  return arStatusDisplayPayment.find(value => parseInt(value) === parseInt(status));
}

const getAccount = (data) => {
  return data?.account;
}

const getClient = (data) => {
  return data?.client;
}

const getCharge = (data) => {
  return data?.charge;
}

const getFatura = (data) => {
  return data
}

const getInstallments = (data) => {
  return data?.installments;
}

const getPersonalization = (data) => {
  return data?.account?.personalization;
}

module.exports = {
  callApi: callApi,
  getFatura: getFatura,
  getAccount: getAccount,
  getClient: getClient,
  getCharge: getCharge,
  getInstallments: getInstallments,
  getPersonalization: getPersonalization,
  displayPayment: displayPayment,
}
