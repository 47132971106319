import './Home.scss';
import Header from '../Header/Header';
import PaymentStatus from '../PaymentStatus/PaymentStatus';
import ChargeInfo from '../ChargeInfo/ChargeInfo';
import { useParams } from "react-router-dom";
import { callApi, getFatura, getAccount, getPersonalization, getClient, getCharge, getInstallments, displayPayment } from '../../services/FaturaService';
import { useEffect, useState } from 'react';
import PaymentType from '../PaymentType/PaymentType';
import OwnerInfo from '../OwnerInfo/OwnerInfo';
import { GiTreeBeehive } from "react-icons/gi";

const Home = () => {
  const [data, setData] = useState(null);
  const [displayPaymentSection, setDisplayPaymentSection] = useState(null);
  const [customStyle, setCustomStyle] = useState({})
  const { faturaUUID } = useParams();
  const [idUrl, setIdUrl] = useState("");

  const [loadingPage, setLoadingPage] = useState(true)

  useEffect(() => {
    //if para verificação da URL parcela
    if (idUrl !== faturaUUID) {
      callApi(faturaUUID)
        .then(response => {
          document.title = response.account.name + " | Fatura";

          setData(response);
          setDisplayPaymentSection(displayPayment(response.charge.status))

          setCustomStyle(customStyle => ({
            ...customStyle,
            background: `linear-gradient(to bottom, ${getPersonalization(response).primary_color} 190px, rgba(255, 255, 255, 1) 190px)`,
          }))

          setTimeout(() => {
            setLoadingPage(false);
          }, 1500);

        }).catch(error => {
          console.log('Error', error);
        });
    }
    setIdUrl(faturaUUID);
  }, [idUrl, faturaUUID])

  return (
    <div className="home" style={{ ...customStyle }}>
      <div className="home-container">
        {data && <Header account={getAccount(data)} />}
        {data && <PaymentStatus charge={getCharge(data)} />}
        {data && <ChargeInfo charge={getCharge(data)} client={getClient(data)} faturaUUID={faturaUUID} installments={getInstallments(data)} />}
        {data && displayPaymentSection && <PaymentType charge={getCharge(data)} client={getClient(data)} fatura={getFatura(data)} installments={getInstallments(data)} faturaUUID={faturaUUID} />}
        {data && <OwnerInfo account={getAccount(data)} />}
      </div>
      <div className="footer">Esta fatura é intermediada por <a href="https://bee.cash">Bee Cash Instituição de Pagamentos LTDA</a></div>
      <div className={!loadingPage ? "load-page-off" : "load-page"}>
        <div className="background-load-before">
          <div className="background-opacity">
            <div className="icon-load">
              <GiTreeBeehive size="8rem" color="#FFB101" id='animate-icon' />
              <h1>Coletando informações da fatura...</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home;