import './OwnerInfo.scss';

const OwnerInfo = ({ account }) => {
  return (
    <section className="box-section">
      Esta cobrança é de responsabilidade única e exclusiva de <strong>{account.name}</strong> documento <strong>{account.document}</strong> e a <a href='https://bee.cash/' target="_blank" without='true' rel="noreferrer"> Bee.Cash</a> não se responsabiliza pelo produto ou serviço prestado, em caso de dúvida entre em contato com seu fornecedor.
    </section>
  );
}

export default OwnerInfo;